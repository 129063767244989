<template>
  <main
    id="content"
    class="main-content page-questions pt-0 bg-oct"
    role="main"
  >
    <div
      class="
        page-questions__header
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <router-link to="/" class="link fs-24">
        <i class="fas fa-chevron-left mr-3" /> Voltar
      </router-link>
      <span
        style="color: #fff; font-size: 20px; width: 900px; text-align: center"
        >Banco de Questões</span
      >

      
      <router-link :to="{ name: 'questao_create' }" class="link font-bold">
        <i class="fas fa-plus" />&nbsp;Adicionar questão
      </router-link>
      <router-link :to="{ name: 'questoes_trash' }" class="link font-bold" v-if="!isProfessor">
        <i class="fas fa-trash" />&nbsp;Lixeira de questões
      </router-link>

      <router-link :to="{ name: 'questoes_trash' }" class="link font-bold" v-if="isProfessor">
        <i class="fas fa-trash" />&nbsp;Minha Lixeira de questões
      </router-link>
    </div>

    <div class="page-questions__content">
      <!-- Start Content left-->
      <div class="content-left">
        <QuestaoFilter
          :filtro="filtro"
          :disciplinasDisponiveis="disciplinasDisponiveis"
          :assuntosDisponiveis="assuntosDisponiveis"
          :anosDisponiveis="anosDisponiveis"
        />
      </div>
      <!-- End Content left-->

      <!-- Start Content center -->
      <div class="content-right px-4">
        <p class="mb-4">
          Exibindo {{ questoes.length }} questões de {{ total }} encontradas
        </p>
        <questao-list
          v-for="questao in questoes"
          :key="questao.id"
          :questao-prop="questao"
          :is-list-prova="false"
          :is-list-inserida-prova="false"
          :emailCriador="questao.criadaPor.email"
        />

        <Pagination
          :total="total"
          :page="this.page"
          :total-paginas="this.totalPaginas"
          :itens-per-page="this.itensPerPage"
          @change-page="onChangePage"
        >
        </Pagination>
      </div>
    </div>
  </main>
</template>

<script>
import Pagination from "@/components/questoes/Pagination.vue";
import QuestaoList from "@/components/questoes/QuestaoList";
import QuestaoFilter from "@/components/questoes/QuestaoFilter";
import Swal from "sweetalert2";
import { constantes } from "@/constants";
import { checkUserHaveRole } from "@/tools";
export default {
  components: {
    QuestaoList,
    Pagination,
    QuestaoFilter,
  },
  data() {
    return {
      user: [],
      isProfessor: false,
      anoSelecionado: null,
      instituicao: [],
      disciplinasDisponiveis: [],
      assuntosDisponiveis: [],
      anosDisponiveis: [],
      showRespostas: false,
      page: 1,
      total: 0,
      itensPerPage: 10,
      questoes: [],
      totalPaginas: 0,
      filtro: {
        page: 1,
        size: 1,
        disciplinaSelecionada: null,
        assuntosSelecionados: null,
        anoSelecionado: null,
        tipo: "",
        search: "",
        origem: "",
      },
    };
  },
  created() {
    this.loadUser();
    if (checkUserHaveRole([constantes.ROLE_PROFESSOR])) {
      this.isProfessor = true;
    } else {
      this.isProfessor = false;
    }
  },
  methods: {
    loadUser() {
      this.$api
        .get("users/info")
        .then((response) => {
          this.user = response.data;
          this.$root.$emit("Spinner::hide");
          this.loadQuestoes();
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    loadInstituicao() {
      // /api/instituicao_ensinos/{id}
    },
    deleta(questao) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma remoção da questão?",
        text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$root.$emit("Spinner::show");

          this.$api
            // .delete("questaos/" + questao.id)
            .put("questaos/" + questao.id + "/trash", {})
            .then(() => {
              this.$root.$emit("Spinner::hide");

              Swal.fire({
                position: "center",
                icon: "success",
                title: "Questão removida com sucesso!",
                showConfirmButton: false,
                timer: 1500,
              });
              setTimeout(function () {
                location.reload();
              }, 1000);
            })
            .catch((error) => {
              this.$root.$emit("Spinner::hide");
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Ocorreu algum erro :(",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    onChangePage(value) {
      this.filtro.page = value;
      this.filtro.disciplinaSelecionada = null;
      console.log(value);
      this.loadQuestoes();
    },

    loadQuestoes() {
      this.$root.$emit("Spinner::show");
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.filtro.size = this.itensPerPage;
      this.page = this.filtro.page;
      console.log(this.filtro);
      this.$api
        .post("/questoes-paginacao", this.filtro)
        .then((response) => {
          this.total = response.data.totalItens;
          this.totalPaginas = response.data.paginas;

          this.questoes = [];
          response.data.data.forEach((res) => {
            if (idInstituicao == 0 || res.trashAt === null) {
              this.questoes.push(res);
            }
          });
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          this.$root.$emit("Spinner::hide");

          if (error.response.status == 403) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">Oops! Você não tem permissão para executar esta ação.</span>',
              showConfirmButton: false,
              timer: 2500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.violations[0].message +
                "</span>",
              showConfirmButton: false,
              timer: 2500,
            });
          }
          this.$root.$emit("Spinner::hide");
        });
    },
    filtrar() {
      if (this.filtro.disciplinaSelecionada == undefined) {
        this.filtro.disciplinaSelecionada = null;
      }

      if (this.filtro.tipo == "todas") {
        this.filtro.tipo = "";
      }

      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.$root.$emit("Spinner::show");
      this.filtro.size = "10";
      this.$api
        .post("/questoes-paginacao", this.filtro)
        .then((response) => {
          this.questoes = []
          response.data.data.forEach((res) => {
            if (idInstituicao == 0 || res.trashAt === null) {
              this.questoes.push(res);
            }
          });
          this.total = response.data.totalItens;
          this.totalPaginas = response.data.paginas;
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          this.$root.$emit("Spinner::hide");

          if (error.response.status == 403) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">Oops! Você não tem permissão para executar esta ação.</span>',
              showConfirmButton: false,
              timer: 2500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.violations[0].message +
                "</span>",
              showConfirmButton: false,
              timer: 2500,
            });
          }
          this.$root.$emit("Spinner::hide");
        });
    },
    loadDisciplinas() {
      this.$root.$emit("Spinner::show");

      this.$api.get("disciplinas").then((response) => {
        this.disciplinasDisponiveis = response.data;
        this.$root.$emit("Spinner::hide");
      });
    },
    changeAno(ano) {
      this.anoSelecionado = ano;
      console.log(this.anoSelecionado);
      console.log(this.disciplinasDisponiveis);
    },
    loadAssuntos(idDisciplina) {
      this.$api.get("assuntos-disciplina/" + idDisciplina).then((response) => {
        this.assuntosDisponiveis = response.data;
        this.$root.$emit("Spinner::hide");
      });
    },
    loadAnos() {
      this.$api.get("ano_ensinos").then((response) => {
        response.data.forEach((res) => {
          if (res.trashAt == null) {
            this.anosDisponiveis.push(res);
          }
        });
        // this.anosDisponiveis = response.data;
        this.anosDisponiveis.map(function (entry) {
          entry.text = entry.titulo;
        });
        this.$root.$emit("Spinner::hide");
      });
    },
    favoritarQuestao(questao) {
      console.log(questao);
      this.$api
        .post("/questao-favoritar/" + questao.id, {})
        .then((response) => {
          console.log(response);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Questão favoritada com sucesso !",
            showConfirmButton: false,
            timer: 2500,
          });
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 400) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Você ja favoritou essa questão!",
              showConfirmButton: false,
              timer: 2500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Ocorreu um erro :(",
              showConfirmButton: false,
              timer: 2500,
            });
          }
        });
    },
  },
};
</script>
